<template>
  <v-card class="elevation-1">
    <v-card-title>
      Sales
    </v-card-title>
    <v-card-text>
      <v-row class="mt-2">
        <v-col
          cols="12"
          lg="4"
          md="4"
          class="mb-0 pb-0"
        >
          <!-- Sales Date -->
          <v-dialog
            ref="dialog"
            v-model="modalSalesDate"
            :return-value.sync="salesDateValue"
            persistent
            width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="salesDate"
                label="Sales Date"
                outlined
                dense
                placeholder="Sales Date"
                v-bind="attrs"
                :append-icon="icon.calendar"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="salesDate"
              scrollable
            >
              <v-btn
                text
                color="primary"
                @click="modalSalesDate=false"
              >
                Cancel
              </v-btn>
              <v-spacer></v-spacer>
              <v-btn
                text
                color="primary"
                @click="modalSalesDate=false"
              >
                OK
              </v-btn>
            </v-date-picker>
          </v-dialog>
        </v-col>
        <v-col>
          <v-btn
            color="primary"
            class="mx-1"
            @click="filter"
          >
            <v-icon
              small
            >
              {{ icon.filter }}
            </v-icon>
            Filter
          </v-btn>
          <v-btn
            color="primary"
            class="mx-1"
            @click="resetFilter"
          >
            <v-icon
              small
            >
              {{ icon.reset }}
            </v-icon>
            Reset
          </v-btn>
          <v-btn color="success" :to="{name: 'cart'}" class="float-right">Add New</v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
            class="mb-2"
            @keypress.enter="searchTable=search"
          ></v-text-field>
        </v-col>
      </v-row>
    </v-card-text>
    <v-data-table
      :headers="headers"
      :items="items"
      :search="searchTable"
      :options.sync="options"
      :server-items-length="totalItems"
      :loading="loading"
      dense
    >
      <template #[`item.amount`]="{ item }">
        <span>{{ Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2, }).format(item.amount) }}</span>
      </template>
      <template #[`item.action`]="{ item }">
        <div v-if="item.created_at.includes(dateToday) && item.cancelled_at == null">
          <!-- <v-btn
            x-small
            icon
            @click="confirmOverrideItem(item)"
            title="Override Transaction"
          >
            <v-icon>{{ icon.edit }}</v-icon>
          </v-btn> -->
          <v-btn
            x-small
            icon
            title="Void Transaction"
            @click="confirmVoidItem(item)"
          >
            <v-icon>{{ icon.void }}</v-icon>
          </v-btn>
          <v-btn
            x-small
            icon
            title="Print Transaction"
            @click="confirmPrint(item)"
          >
            <v-icon>{{ icon.print }}</v-icon>
          </v-btn>
        </div>
      </template>
    </v-data-table>

    <snackbar
      v-if="responseMessage.length > 0"
      :show="responseMessage.length > 0"
      :text="responseMessage"
      :color="responseMessageStatus"
      @close="responseMessage=''"
    ></snackbar>

    <confirm-password
      :show="showConfirmVoid"
      @cancel="showConfirmVoid=false"
      @execute="voidItem">
    </confirm-password>

    <confirm-password
      :show="showConfirmOverride"
      @cancel="showConfirmOverride=false"
      @execute="overrideItem">
    </confirm-password>

    <confirm-password
      :show="showConfirmPrint"
      @cancel="showConfirmPrint=false"
      @execute="print">
    </confirm-password>
  </v-card>
</template>

<script>
import { ref, watch } from '@vue/composition-api'
import getData from '@/composables/getData'
import snackbar from '@/components/SnackBar.vue'
import { mdiFileCancelOutline, mdiPencilOutline, mdiPrinter, mdiPrinterPos } from '@mdi/js'
import crud from '@/composables/crud'
import ConfirmPassword from '@/components/ConfirmPassword.vue'
import { useRouter } from '@/@core/utils'
import blob from '@/composables/blob'
import printJS from 'print-js'
import fnx from '@/functions/fn'

export default {
  components: {
    snackbar,
    ConfirmPassword,
  },

  setup(props) {
    const { router } = useRouter()

    const d = new Date()
    const arrD = d.toISOString().substring(0, 10).split("-")
    const dateToday = `${arrD[1]}/${arrD[2]}/${arrD[0]}`

    const search = ref('')
    const headers = ref([
      { text: 'Reference Number', align: 'start', value: 'reference_number' },
      { text: 'Sold To', align: 'start', value: 'sold_to' },
      { text: 'Buyer Type', align: 'start', value: 'buyer_type' },
      { text: 'Date Purchased', align: 'end', value: 'created_at' },
      { text: 'Amount', align: 'end', value: 'amount' },
      { text: 'Action', align: 'center', value: 'action' },
    ])
    const items = ref([])
    const totalItems = ref(0)
    const searchTable = ref('')
    const options = ref({})
    const loading = ref(false)
    const icon = {
      void: mdiFileCancelOutline,
      edit: mdiPencilOutline,
      print: mdiPrinter,
    }
    const showConfirmOverride = ref(false)
    const showConfirmVoid = ref(false)
    const showConfirmPrint = ref(false)
    const selectedItem = ref(null)

    const error = ref(null)
    const receiptData = ref(null)

    const modalSalesDate = ref(false)
    const salesDateValue = d.toISOString().substring(0, 10)
    const salesDate = ref('')

    const filterValue = ref('')

    const {
      update,
      responseMessage,
      responseMessageStatus,
    } = crud()

    const listUri = `${process.env.VUE_APP_URI}/api/cart/masterlist`

    watch(options, () => {
      getData(listUri, items, totalItems, loading, options, search)
    })

    watch(searchTable, () => {
      options.value.page = 1
      getData(listUri, items, totalItems, loading, options, search)
    })

    const filter = async () => {
      if (salesDate.value !== '') {
        filterValue.value = fnx.base64_encode({ salesDate: salesDate.value })
        getData(listUri, items, totalItems, loading, options, search, filterValue.value)
      } else {
        responseMessage.value = 'Sales Date(s) are required.'
        responseMessageStatus.value = 'error'
      }
    }

    const resetFilter = () => {
      filterValue.value = ''
      salesDate.value = ''
      items.value = []
      totalItems.value = 0
    }

    const confirmOverrideItem = (item) => {
      selectedItem.value = item
      showConfirmOverride.value = true
    }

    const overrideItem = () => {
      router.push({ name: 'cart', params: { 'id': selectedItem.value.id}})
    }

    const confirmVoidItem = async (item) => {
      showConfirmVoid.value = true
      selectedItem.value = item
    }

    const confirmPrint = async (item) => {
      showConfirmPrint.value = true
      selectedItem.value = item
    }

    const voidItem = async () => {
      await update(`${process.env.VUE_APP_URI}/api/cart/${selectedItem.value.id}/void`, loading, {}, undefined)
    }

    const print = async () => {
      await blob(`${process.env.VUE_APP_URI}/api/cart/${selectedItem.value.reference_number}/receipt`, receiptData, loading, error)
      if (error.value === null) {
        const url = window.URL.createObjectURL(new Blob([receiptData.value], { type: 'application/pdf' }))
        printJS(url)
        // window.open(url)
      }
    }

    return {
      search,
      headers,
      items,
      searchTable,
      options,
      totalItems,
      loading,
      icon,

      showConfirmVoid,
      showConfirmOverride,
      showConfirmPrint,

      print,
      overrideItem,
      voidItem,
      confirmVoidItem,
      confirmOverrideItem,
      confirmPrint,
      responseMessage,
      responseMessageStatus,
      dateToday,

      salesDateValue,
      salesDate,
      modalSalesDate,

      filter,
      resetFilter,
    }
  },
}
</script>
